export const CONNECTION_CONNECTED = 'CONNECTION_CONNECTED'
export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED'

export const ERROR = 'ERROR'

export const CONFIGURE = 'CONFIGURE'
export const CONFIGURE_RETURNED = 'CONFIGURE_RETURNED'

export const STAKE = 'STAKE'
export const STAKE_RETURNED = 'STAKE_RETURNED'

export const GET_REWARDS = 'GET_REWARDS'
export const GET_REWARDS_RETURNED = 'GET_REWARDS_RETURNED'

export const EXIT = 'EXIT'
export const EXIT_RETURNED = 'EXIT_RETURNED'

export const WITHDRAW = 'WITHDRAW'
export const WITHDRAW_RETURNED = 'WITHDRAW_RETURNED'

export const GET_BALANCES = 'GET_BALANCES'
export const GET_BALANCES_RETURNED = 'GET_BALANCES_RETURNED'

export const GET_BALANCES_PERPETUAL = 'GET_BALANCES_PERPETUAL'
export const GET_BALANCES_PERPETUAL_RETURNED = 'GET_BALANCES_PERPETUAL_RETURNED'

export const PROPOSE = 'PROPOSE'
export const PROPOSE_RETURNED = 'PROPOSE_RETURNED'

export const GET_PROPOSALS = 'GET_PROPOSALS'
export const GET_PROPOSALS_RETURNED = 'GET_PROPOSALS_RETURNED'

export const VOTE_FOR = 'VOTE_FOR'
export const VOTE_FOR_RETURNED = 'VOTE_FOR_RETURNED'

export const VOTE_AGAINST = 'VOTE_AGAINST'
export const VOTE_AGAINST_RETURNED = 'VOTE_AGAINST_RETURNED'

export const GET_CLAIMABLE_ASSET = 'GET_CLAIMABLE_ASSET'
export const GET_CLAIMABLE_ASSET_RETURNED = 'GET_CLAIMABLE_ASSET_RETURNED'

export const CLAIM = 'CLAIM'
export const CLAIM_RETURNED = 'CLAIM_RETURNED'

export const GET_CLAIMABLE = 'GET_CLAIMABLE'
export const GET_CLAIMABLE_RETURNED = 'GET_CLAIMABLE_RETURNED'

export const GET_YCRV_REQUIREMENTS = 'GET_YCRV_REQUIREMENTS'
export const GET_YCRV_REQUIREMENTS_RETURNED = 'GET_YCRV_REQUIREMENTS_RETURNED'

export const REGISTER_VOTE = 'REGISTER_VOTE'
export const REGISTER_VOTE_RETURNED = 'REGISTER_VOTE_RETURNED'

export const GET_VOTE_STATUS = 'GET_VOTE_STATUS'
export const GET_VOTE_STATUS_RETURNED = 'GET_VOTE_STATUS_RETURNED'
